import React from "react";
import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from '../../sections/home/index/section-what-we-do1';
import { data } from "../../../globals/data/about-us";

export default function AboutUsPage() {
    return (
        <>
            <Banner _data={data.banner} />
            <SectionWhatWeDo1 _data={data.whatwedo} />
        </>
    )
}