import { NavLink } from "react-router-dom"
import { publicUrlFor, loadScript } from "../../../../globals/constants";
import { useEffect } from "react";

export default function SectionSlider2() {

    useEffect(() => {
        loadScript('/assets/js/slider-home2.js',true);
        loadScript('/assets/js/bg-image-move.js',true);
    })

    return (
        <>
            <div className="twm-slider2-wrap">
                {/* Swiper */}
                <div className="swiper twm-slider2">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide bg-cover" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/HomeImage.jpg')})` }}>
                            <div className="h-banner-wrap">
                                <div className="h-banner-left">
                                    <div className="h-bnr-top">
                                        <h2>
                                            <span className="title-outline">Ilunga</span>
                                            Transportation
                                        </h2>
                                        <p>Dependable freight services, specializing in timely and secure deliveries across various industries nationwide.</p>
                                        {/* <NavLink to="/misc/contact-us" className="h-bnr-btn">About Us</NavLink> */}
                                    </div>
                                </div>
                                <div className="circle-left-top zoon-in-out" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}