/* eslint-disable jsx-a11y/iframe-has-title */
import Banner from "../../sections/common/banner";
import { data } from "../../../globals/data/contact-us";
import { publicUrlFor } from "../../../globals/constants";

export default function ContactPage() {
    return (
        <>
            <Banner _data={data.banner} />
            
            <div className="section-full  p-t120 p-b120">
                <div className="section-content">
                    <div className="container">
                        <div className="contact-one">
                            {/* TITLE START*/}
                            <div className="section-head left wt-small-separator-outer">
                                <h2 className="wt-title m-b30">Contact Us</h2>
                            </div>
                            {/* TITLE END*/}
                            {/* CONTACT FORM*/}
                            <div className="contact-one-inner  site-bg-sky-blue-light">
                                <div className="row">
                                    {/* <div className="col-lg-8 col-md-12">
                                        <div className="contact-form-outer">
                                            <form className="cons-contact-form" method="post" action="form-handler2.php">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group mb-3">
                                                            <input name="username" type="text" required className="form-control" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="email" type="text" className="form-control" required placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="phone" type="text" className="form-control" required placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="subject" type="text" className="form-control" required placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <textarea name="message" className="form-control" rows={3} placeholder="Message" defaultValue={""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn-half site-button"><span>Submit Now</span><em /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-12">
                                        <div className="contact-info  site-bg-dark bg-no-repeat bg-bottom-left" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/background/contact-bg.png')})` }}>
                                            {/* TITLE START*/}
                                            <div className="section-head-small white mb-4">
                                                <h3 className="wt-title">Let's get in touch</h3>
                                            </div>
                                            {/* TITLE END*/}
                                            <div className="contact-info-section">
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-map-marker"><span /></div>
                                                    <p>Atlanta, GA, USA</p>
                                                </div>
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-mobile-phone custome-size"><span /></div>
                                                    <p><a href="tel:+216-761-8331">+01 404-915-6849</a></p>
                                                </div>
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-envelope-o"><span /></div>
                                                    <p>ilungakabwende@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}