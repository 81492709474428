
import SectionServices2 from "../../sections/home/index2/section-services2";
import SectionSlider2 from "../../sections/home/index2/section-slider2";
// import SectionTestimonials2 from "../../sections/home/index2/section-testimonials2";
import { data } from "../../../globals/data/home2";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function Home2Page() {

    useEffect(() => {
        loadScript("/assets/js/custom.js", true)
    })

    return (
        <>
            <SectionSlider2 />
            <SectionServices2 _data={data.services} />
            {/* <SectionAchivements1 _data={data.achivements} />
            <SectionEstimation2 _data={data.estimation} /> */}
            {/* <SectionClients1 _data={data.clients} /> */}
            {/* <SectionTestimonials2 _data={data.testimonials} /> */}
            {/* <SectionProjects2 _data={data.projects} /> */}
        </>
    )
}

export default Home2Page;